






import {Component} from 'vue-property-decorator';
import OButton from './OButton.vue';

@Component({
  components: {
    OButton,
  },
})
export default class SecondaryButton extends OButton {}
