import { render, staticRenderFns } from "./SecondaryButton.vue?vue&type=template&id=6f89554f&scoped=true&"
import script from "./SecondaryButton.vue?vue&type=script&lang=ts&"
export * from "./SecondaryButton.vue?vue&type=script&lang=ts&"
import style0 from "./SecondaryButton.vue?vue&type=style&index=0&id=6f89554f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f89554f",
  null
  
)

export default component.exports