






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class OButton extends Vue {
  @Prop() onClick!: (event: MouseEvent) => void;
  @Prop({default: false}) disabled!: boolean;

  clickHandler(event: MouseEvent) {
    if (this.disabled === false) {
      this.onClick(event);
    }
  }
}
